export const protectionTitle = `
Our Mission
`;
export const protectionDescription = `
Our mission is empowering businesses with innovative DevOps solutions that accelerate software delivery, enhance operational efficiency, and foster seamless collaboration between development and operations teams.
`;

export const protectionImgTitle = `We Are Open for Collaborations!`;
export const protectionImgDescription = `
At HybridOps, we believe in the power of partnerships—let’s work together to create innovative solutions and achieve shared success.







`;

export const protectionSkillOneTitle = `remove`;
export const protectionSkillOneDescription = `
remove
`;

export const protectionSkillTwoTitle = `remove`;
export const protectionSkillTwoDescription = `
remove
`;

import CompanyCard from '../../common/Cards/CompanyCard';
// import Companies from './Companies';
import security from '../../../assets/img/security.svg';
import privacy from '../../../assets/img/privacy.svg';
import certifiedIndustry from '../../../assets/img/certifiedIndustry.svg';
import {
  skillOneDescription,
  skillOneTitle,
  skillTwoDescription,
  skillTwoTitle,
  skillThreeDescription,
  skillThreeTitle,
} from './text';

const Skills = () => {
  const skillData = [
    {
      imageSrc: security,
      imageAlt: skillOneTitle,
      title: skillOneTitle,
      description: skillOneDescription,
    },
    {
      imageSrc: privacy,
      imageAlt: skillTwoTitle,
      title: skillTwoTitle,
      description: skillTwoDescription,
    },
    {
      imageSrc: certifiedIndustry,
      imageAlt: skillThreeTitle,
      title: skillThreeTitle,
      description: skillThreeDescription,
    },
  ];

  return (
    <section className="skill mb-4 pb-5 z-1" id="skills" aria-label="Skills Section">
      <div className="container">
        <div className="row g-3 justify-content-center">
          {skillData.map((skill, index) => (
            <div key={index} className="col-sm-12 col-lg-4">
              <div className="mt-3 mb-1">
                <CompanyCard
                  imageSrc={skill.imageSrc}
                  imageAlt={skill.imageAlt}
                  title={skill.title}
                  description={skill.description}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;

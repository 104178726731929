export const securityTitle = `Why Choose Us ?`;

export const securityDescription = `
At HybridOps, we deliver streamlined, reliable, and secure DevOps solutions to accelerate your business's growth. Here's why businesses trust us:
`;

export const securityWorks = [
  '24/7 Support & remote admit',
  'Tailored DevOps solutions crafted to fit your specific objectives',
  'Faster delivery cycles with integrated security for enhanced efficiency',
];

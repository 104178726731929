export const serviceOne = `
Experience the power of streamlined software delivery with our expert-crafted Continuous Integration and Continuous Deployment (CI/CD) pipelines. We ensure faster, error-free deployments, enabling your team to deliver updates and innovations with confidence.`;
export const serviceTwo = `
Say goodbye to manual provisioning! We revolutionize infrastructure management by using code to ensure unmatched consistency, rapid scaling, and simplified disaster recovery.`;
export const serviceThree = `
Ready to harness the cloud? We facilitate seamless migrations to platforms like AWS, Google Cloud, and Azure. Post-migration, our ongoing management optimizes performance and ensures cost-efficiency, keeping your operations at their peak.`;
export const serviceFour = `
With tools like Docker and Kubernetes, we make your applications scalable, portable, and optimized for resource efficiency. Deploying and managing your workloads has never been this effortless.`;
export const serviceFive = `
Security is at the heart of our DevOps strategy. We embed robust security measures across the lifecycle, leveraging automated tools to perform continuous vulnerability scans and ensure compliance, keeping your operations protected at all times.`;
export const serviceSix = `
Stay ahead of the curve with real-time insights into your applications and infrastructure. Our advanced monitoring services proactively resolve issues, enhance performance, and ensure your systems operate at peak efficiency.
`;

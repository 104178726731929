import software from '../../../assets/img/software.svg';
import hexatech from '../../../assets/img/hexatech.svg';
import metrics from '../../../assets/img/metrics.svg';
import tripletech from '../../../assets/img/tripletech.svg';

export const skillOneTitle = `Security Services & Data Privacy
`;
export const skillOneDescription = `
Our team implements robust security protocols, including automated security scans, vulnerability assessments, and compliance management. Additionally, we ensure that your data is protected through advanced encryption techniques and strict privacy practices, safeguarding your business from potential threats. `;

export const skillTwoTitle = `Industry Certified Experts`;
export const skillTwoDescription = `
Our team of certified professionals holds credentials from top cloud providers, including Google Cloud Professional DevOps Engineer, AWS Certified DevOps Engineer, Microsoft Azure DevOps Expert, and Kubernetes certifications (CKA/CKAD).
`;

export const skillThreeTitle = `Automation & Continuous Improvement

`;
export const skillThreeDescription = ` 
By automating repetitive tasks and embracing modern CI/CD practices, we enable rapid deployments and consistent delivery. Our focus on continuous improvement ensures that your infrastructure remains scalable, secure, and optimized, adapting seamlessly to your evolving business needs.
 `;

export const numOfCompanies = ` 2300 + `;

export const companyData = [
  { imageSrc: software, imageAlt: 'Software Company' },
  { imageSrc: hexatech, imageAlt: 'Hexatech Company' },
  { imageSrc: metrics, imageAlt: 'Metrics Company' },
  { imageSrc: tripletech, imageAlt: 'Tripletech Company' },
];
